import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authReq: any;
    if(environment.production){
      authReq = request.clone({
        body:{
          ...request.body,
          env: "prod"
        }
      });
    } else {
      authReq = request.clone({
        body:{
          ...request.body,
          env: "dev"
        }
      });
    }
    return next.handle(authReq);
  }
}
