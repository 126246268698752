// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // cloudFuncURL: "http://localhost:7071/api",
  cloudFuncURL: "https://vive-app-api-dev.azurewebsites.net/api",

  firebaseConfig: {
    apiKey: "AIzaSyBEAMSmUU5WZf5y3m63EJDtlZZun40TP4Y",
    authDomain: "vive-dev-45a7a.firebaseapp.com",
    projectId: "vive-dev-45a7a",
    storageBucket: "vive-dev-45a7a.appspot.com",
    messagingSenderId: "28906311863",
    appId: "1:28906311863:web:e932b9e6dcf882c1383d32"
  },
  sendEmailUrl: 'https://csspl-notification-backend-dev.azurewebsites.net/api/sendEmailHandler'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
