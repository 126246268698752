<table class="responsive-card-table table table-rounded">
    <thead class="bg-body-secondary">
     <tr>
      <th scope="col">Action</th>
      <th scope="col">Cluster</th>
      <th scope="col">Pending Approval</th>
      <th scope="col">Overdue</th>
      <th scope="col">Complete</th>
     </tr>
    </thead>
    <tbody>
     <tr>
        <td data-label="Action">
            <div ngbDropdown class="d-inline-block dropright dropdown dropdown-inline">
                <button type="button" class="btn btn-primary btn-icon moreactions action-btn" id="dropdownBasic1" ngbDropdownToggle>
                    <i class="bi bi-three-dots-vertical"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropright-mr">
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-eye-fill fs-3 text-warning mr-2"></i>
                        <span class="mt-1">View</span>
                    </button>
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-cart-check-fill fs-3 text-primary mr-2"></i>
                        <span class="mt-1">Checkout</span>
                    </button>
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-pencil-square fs-3 text-success mr-2"></i>
                        <span class="mt-1">Edit</span>
                    </button>
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-cloud-download-fill fs-3 text-info mr-2"></i>
                        <span class="mt-1">Download</span>
                    </button>
                </div>
            </div>
        </td>
        <td data-label="Cluster">Learning Complex 1</td>
        <td data-label="Pending Approval">9</td>
        <td data-label="Overdue">2</td>
        <td data-label="Complete">55</td>
     </tr>
     <tr>
         <td data-label="Action">
            <div ngbDropdown class="d-inline-block dropright dropdown dropdown-inline">
                <button type="button" class="btn btn-primary btn-icon moreactions action-btn" id="dropdownBasic2" ngbDropdownToggle>
                    <i class="bi bi-three-dots-vertical"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropright-mr">
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-eye-fill fs-3 text-warning mr-2"></i>
                        <span class="mt-1">View</span>
                    </button>
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-cart-check-fill fs-3 text-primary mr-2"></i>
                        <span class="mt-1">Checkout</span>
                    </button>
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-pencil-square fs-3 text-success mr-2"></i>
                        <span class="mt-1">Edit</span>
                    </button>
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-cloud-download-fill fs-3 text-info mr-2"></i>
                        <span class="mt-1">Download</span>
                    </button>
                </div>
            </div>
         </td>
        <td scope="row" data-label="Cluster">Learning Complex 2</td>
        <td data-label="Pending Approval">21</td>
        <td data-label="Overdue">2</td>
        <td data-label="Complete">46</td>
     </tr>
     <tr>
         <td data-label="Action">
            <div ngbDropdown class="d-inline-block dropright dropdown dropdown-inline">
                <button type="button" class="btn btn-primary btn-icon moreactions action-btn" id="dropdownBasic3" ngbDropdownToggle>
                    <i class="bi bi-three-dots-vertical"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropright-mr">
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-eye-fill fs-3 text-warning mr-2"></i>
                        <span class="mt-1">View</span>
                    </button>
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-cart-check-fill fs-3 text-primary mr-2"></i>
                        <span class="mt-1">Checkout</span>
                    </button>
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-pencil-square fs-3 text-success mr-2"></i>
                        <span class="mt-1">Edit</span>
                    </button>
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-cloud-download-fill fs-3 text-info mr-2"></i>
                        <span class="mt-1">Download</span>
                    </button>
                </div>
            </div>
         </td>
        <td scope="row" data-label="Cluster">Learning Complex 3</td>
        <td data-label="Pending Approval">18</td>
        <td data-label="Overdue">8</td>
        <td data-label="Complete">54</td>
     </tr>
     <tr>
         <td data-label="Action">
            
            <div ngbDropdown class="d-inline-block dropright dropdown dropdown-inline">
                <button type="button" class="btn btn-primary btn-icon moreactions action-btn" id="dropdownBasic4" ngbDropdownToggle>
                    <i class="bi bi-three-dots-vertical"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropright-mr">
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-eye-fill fs-3 text-warning mr-2"></i>
                        <span class="mt-1">View</span>
                    </button>
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-cart-check-fill fs-3 text-primary mr-2"></i>
                        <span class="mt-1">Checkout</span>
                    </button>
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-pencil-square fs-3 text-success mr-2"></i>
                        <span class="mt-1">Edit</span>
                    </button>
                    <button ngbDropdownItem class="py-2 d-flex align-items-center">
                        <i class="bi bi-cloud-download-fill fs-3 text-info mr-2"></i>
                        <span class="mt-1">Download</span>
                    </button>
                </div>
            </div>
         </td>
        <td scope="row" data-label="Cluster">Learning Complex 4</td>
        <td data-label="Pending Approval">8</td>
        <td data-label="Overdue">11</td>
        <td data-label="Complete">19</td>
     </tr>
    </tbody>
   </table>