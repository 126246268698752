import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './component/sections/navbar/navbar.component';
import { NgbModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { TableComponent } from './component/sections/table/table.component';
import { CardIconComponent } from './component/sections/card-icon/card-icon.component';

@NgModule({
  declarations: [NavbarComponent, TableComponent, CardIconComponent],
  imports: [CommonModule, BrowserModule, NgbModule, NgbDropdownModule],
  exports: [],
})
export class SharedModule {
  public isCollapsed = false;
}
